<template>
    <div class="">
        <div class="container">
            <div class="p-3 pb-4">
                <div class="text-center pt-5 mt-5 mb-5">
                    <i class="fal fa-check-circle fa-5x text-primary" v-if="success"></i>
                    <i class="fal fa-exclamation-triangle fa-5x text-danger" v-else></i>
                </div>
                <h5 class="fw-bolder text-center" v-if="success">정상적으로 처리되었습니다.</h5>
                <h5 class="fw-bolder text-center" v-else>정상적으로 처리되지 않았습니다.</h5>
            </div>
        </div>

        <div class="container footer_btn">
            <router-link to="/main" class="btn bg-pale text-primary w-100">확인</router-link>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main'
export default {
    data(){
        return{
            title: '',

            success: false,
        }
    },
    created(){
        if( this.$route.params.idx == "0" ){
            this.success = false;
        }else{
            this.success = true;
        }
        eventBus.pageTitle(this.title)
        eventBus.goPath('/')
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
</style>